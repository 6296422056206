import React from 'react';
import ReactDOM from 'react-dom';
import './index.css';
import App from './App';
import { BrowserRouter } from 'react-router-dom';
import {unregister} from './registerServiceWorker';
import 'bootstrap/dist/css/bootstrap.css';

ReactDOM.render(   
<BrowserRouter>
    <App />
</BrowserRouter> ,document.getElementById('root'));
unregister();
